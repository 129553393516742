<template>
  <MiscHeaderComponent />
  <div class="max-container">
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold text-center mb-6">Általános szerződési feltételek</h1>

      <!-- 1. Üzemeltetői adatok -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">1. Üzemeltetői adatok:</h2>
        <p><strong>Tulajdonos:</strong> Hermán Alíz</p>
        <p><strong>Székhely:</strong> 8808 Nagykanizsa, Diófa u. 2.</p>
        <p><strong>Adószám:</strong> 77082112-1-40</p>
        <p><strong>Szerződés nyelve:</strong> magyar</p>
        <p><strong>Email:</strong> info@bilisztori.hu</p>
        <p><strong>Telefon:</strong> +36 30 977 9394</p>
        <p>A tárhelyszolgáltató elérhetőségei: csiszi@csiszi.hu</p>
        <p>A szolgáltató a jelen általános szerződési feltételekben a továbbiakban: üzemeltető.</p>
      </div>

      <!-- 2. Megvásárolható termékek, szolgáltatások köre -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">2. Megvásárolható termékek, szolgáltatások köre</h2>
        <p>A bilisztori.hu honlapon keresztül Hermán Alíz képzőművész könyvének megrendelésére van lehetőség.</p>
      </div>

      <!-- 3. Rendelési információk -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">3. Rendelési információk</h2>
        <p>A megjelenített termékek megrendelhetőek a bilisztori.hu honlap megrendelő felületén keresztül.</p>
        <p>A termékre vonatkozóan megjelenített ár alanyi áfamentes, tartalmazza a szállítás díját.</p>
        <p>Amennyiben az üzemeltető hibás árat tüntet fel a termék mellett, a tőle elvárható gondosság ellenére, és a
          termék
          ára annak általánosan elfogadott árától eltér, úgy az üzemeltető nem köteles a terméket a hibás áron
          szolgáltatni,
          de köteles a megrendelés visszaigazolásában felajánlani a vásárló részére a valós áron történő vásárlás
          lehetőségét. Amennyiben a vásárló ezzel a lehetőséggel nem kíván élni, úgy megilleti a szerződéstől való
          egyoldalú
          elállás joga.</p>
        <h3>A rendelés menete:</h3>
        <ol>
          <li>Kattintson a „Rendeld meg most!” – gombra.</li>
          <li>Írja be az adatait a megrendelési felületen.</li>
          <li>Kattintson a „Megrendelem” gombra.</li>
          <li>Az Ön által megadott email címre rövidesen érkezik egy üzenet a számlaszámmal, ahová a könyv árát kell
            átutalni, illetve megkapja a rendelési azonosítót, amit az utaláskor a közlemény rovatban kell feltüntetni.
          </li>
          <li>A szállítási idő (az összeg beérkezésétől számítva) maximum 7 munkanap.</li>
          <li>A megrendelések feldolgozása munkanapokon történik 8-16 óráig. A megrendelés feldolgozásaként megjelölt
            időpontokon kívül is van lehetőség a megrendelésre, de ha az a munkaidő lejárta után történik, csak az azt
            követő munkanapon kerül feldolgozásra.</li>
        </ol>
      </div>

      <!-- 4. A megrendelt termék ellenértékének és a szállítás díjának fizetésének módja -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">4. A megrendelt termék ellenértékének és a szállítás díjának fizetésének módja
        </h2>
        <p>A megrendelt termék kifizetésére kizárólag a feljebb leírt módon, banki előre utalással van lehetőség.</p>
      </div>

      <!-- 5. Szállítás díjszabása -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">5. Szállítás díjszabása</h2>
        <p>A szállítási díjat tartalmazza a megjelölt ár.</p>
      </div>

      <!-- 8. Szállítási információk -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">8. Szállítási információk</h2>
        <p>A megkapott megrendeléseket a Magyar Posta kézbesíti. A
          csomagok kézbesítése munkanapokon történik, munkaidőben.</p>
        <p>A megrendelési, és szállítási folyamatával kapcsolatosan felmerülő
          esetleges további kérdések esetén az üzemeltető adatai között megadott
          elérhetőségeinken rendelkezésére állunk.</p>
      </div>

      <!-- 9. Elállás joga -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">9. Elállás joga</h2>
        <p>A nem egyedi megrendelésre készített termékek esetén a távollevők között kötött szerződésekről szóló, 45/2014.
          kormányrendelet szabályozása értelmében a jelen pont rendelkezései kizárólag a fogyasztónak minősülő vásárló
          esetében alkalmazhatóak. A fogyasztó a megrendelt termék kézhez vételétől számított 14 munkanapon belül indoklás
          nélkül elállhat a szerződéstől, visszaküldheti a megrendelt, bontatlan csomagolású terméket. Amennyiben a
          fogyasztó él elállási jogával, úgy ezt az üzemeltetővel egyértelmű írásbeli nyilatkozatban köteles közölni
          (postán
          ajánlott tértivevényes küldeményben, vagy e-mailben).</p>
        <p>Az üzemeltető az elállási nyilatkozat kézhezvételét követően haladéktalanul köteles azt visszaigazolni a
          fogyasztó felé. A fogyasztó elállása esetén a megrendelt terméket köteles elállási nyilatkozatának közlésétől
          számított 14 napon belül az üzemeltetőnek visszaküldeni. A visszaküldés költsége a fogyasztót terheli.</p>
        <p>Amennyiben a fogyasztó elállási jogát gyakorolja, úgy az erre vonatkozó nyilatkozat kézhezvételét követő 14
          napon
          belül köteles az üzemeltető a fogyasztónak az általa teljesített fizetéseket visszatéríteni, beleértve a
          szállítás
          díját is. Ez alól kivétel, ha a fogyasztó olyan fuvarozási módot választott, amely többletköltséggel jár, és
          amely
          a szokásos fuvarozástól eltérő. A szolgáltatónak a visszafizetési kötelezettségét mindaddig nem kell
          teljesítenie,
          amíg a szolgáltatott terméket vissza nem kapta, vagy amíg a fogyasztó hitelt érdemlő igazolását a termék
          visszaküldéséről nem kapta meg. A két időpont közötti eltérés esetén az üzemeltető a korábbi időpontot kell,
          hogy
          figyelembe vegye.</p>
        <p>Üzemeltető követelheti a fogyasztótól a nem rendeltetésszerű használatból adódó anyagi kár megtérítését. Ezért
          kiemelten ügyeljen a termék rendeltetésszerű használatára, ugyanis a nem rendeltetésszerű használatából eredő
          károknak megtérítése a fogyasztót terhelik.</p>
        <p>A csomag a vállalkozáshoz történő beérkezését követően, videó kamerával rögzítésre kerül a csomag kibontása,
          illetve a visszaküldött termék megvizsgálása. Erre az esetleges későbbiekben történő félreértések elkerülése
          végett van szükség. (például, hogy a visszaküldött termék sérült, vagy hiányos volt)</p>
      </div>

      <!-- 11. Szavatosság -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">11. Szavatosság</h2>
        <p>A szolgáltatott termék hibája esetén az üzemeltetővel szemben kellékszavatossági igény érvényesítésének van
          helye
          a Ptk. 6:159-167 :§ i szerint. </p>
        <p>Az eladó csak akkor köteles a szavatossági igényt teljesíteni, ha a termék már a vásárláskor is hibás volt.
          (Például repedt képkeret.)</p>
        <p>A szavatossági igényre és annak teljesítésére egyebekben a Polgári törvénykönyvnek a hibás teljesítésre
          vonatkozó
          rendelkezései az irányadók. (Ennek összefoglalóját megtalálják a <a
            href="http://www.nfh.hu/magyar/hasznos/szavatossag" target="_blank">Nemzeti Fogyasztóvédelmi Hatóság
            honlapján</a>.)</p>
      </div>

      <!-- 14. Adatkezelés -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">14. Adatkezelés</h2>
        <p>Az üzemeltető a webáruház a használata során a rendelkezésére bocsátott
          személyes adatokat bizalmasan kezeli, és nem adja ki kívülálló harmadik személy
          számára, kivéve abban az esetben amennyiben a alvállalkozója számára. (Pl:
          futárszolgálat), a megrendelés kézbesítéséhez ez szükséges.
        </p>
        <p>A webáruház böngészése folyamán technikai információk kerülnek rögzítésre
          statisztikai célokból. (IP cím, látogatás időtartama, stb). Ezen adatokat az
          üzemeltető kizárólag jogilag hitelesen indokolt, és alátámasztott esetben adja
          át a hatóságok részére. A szolgáltatás igénybevételéhez cookie-k engedélyezése
          szükséges. Amennyiben nem szeretné engedélyezi a cookie-k használatát,
          letilthatja a böngészője beállításaiban. Cookie-k tiltása esetén a szolgáltatás
          bizonyos elemei csak részben, vagy egyáltalán nem használhatóak. A cookie egy
          olyan fájl, amelyet a szerver küld a felhasználó böngészőjének, és amelyet a
          felhasználó számítógépe tárol. A cookie-ban személyes adat nem kerül tárolásra.
          A megrendelés folyamán rögzített adatokat az üzemeltető a megrendelés
          teljesítéséhez használja fel. Az egyes informatikai rendszerek által, a
          webáruház oldalain leadott megrendelésből készült számla adatai a rendelés
          leadása folyamán megadott adatokkal rögzítésre, és tárolásra kerülnek a
          hatályos számviteli törvényben meghatározott időszakra. A webáruház böngészése
          illetve a regisztráció folyamán biztosított hírlevél feliratkozás során
          megadott adatokat az üzemeltető bizalmasan kezeli, leiratkozás kérhető a
          megadott elérhetőségek valamelyikén. Adatai törlését, módosítását bármikor
          kérheti írásban.
        </p>
      </div>

      <!-- 15. Egyéb rendelkezések -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">15. Egyéb rendelkezések</h2>
        <p>A jelen általános szerződési feltételekben nem szabályozott kérdésekben a
          Ptk (2013.évi V. törvény), fogyasztói szerződéseknél a távollevők között kötött
          szerződésekről szóló 45/2014. kormányrendelet rendelkezései az irányadók.</p>
      </div>
    </div>
  </div>
</template>
  
<script>
import MiscHeaderComponent from '../components/MiscHeaderComponent.vue';
export default {
  name: 'AszfPage',
  components: {
    MiscHeaderComponent,
  }
};
</script>
  
<style scoped>
.max-container {
  max-width: 800px;
  margin: auto;
  text-align: left;
}</style>
  