<template>
  <a id="reszletek"></a>
  <section class="bg-gray-100 py-8 px-4">
    <div class="max-container">
      <div class="container mx-auto">
        <h2 class="text-3xl font-bold text-center text-gray-800 mb-6">A könyvről</h2>
        <p class="text-lg text-gray-600 leading-relaxed mb-4 text-left">
          A "Bilisztori" egy különleges mesekönyv, amely a szobatisztaságra nevelésben segít a legkisebbeknek. A könyv
          különlegességei közé tartozik, hogy:
        </p>
        <ul class="list-none pl-0 text-gray-600 mb-4 text-left">
          <li class="heart-list-item">A grafikák művészi igénnyel készültek, mert abban hiszek, hogy az jó ízlésre,
            esztétikai intelligenciára való nevelést már ebben a korban el kell kezdeni.</li>
          <li class="heart-list-item">Humoros, a legkisebb korosztály nyelvezetén íródott.</li>
          <li class="heart-list-item">Keménytáblás lapozó, gyerek kéz és gyerek nyál biztos 🫠</li>
          <li class="heart-list-item">Újrahasznosított papírból készült.</li>
        </ul>
        <p class="text-lg text-gray-600 leading-relaxed mb-4 text-left"><strong>Paraméterek:</strong></p>
        <ul class="list-none pl-0 text-gray-600 mb-4 text-left">
          <li class="heart-list-item">15 x 15 cm,</li>
          <li class="heart-list-item">22 oldal</li>
          <li class="heart-list-item">ISBN: <i>978-615-01-0277-1</i></li>
        </ul>
        <p class="text-lg text-gray-600 leading-relaxed text-left">
          A könyv ára <strong class="text-gray-800">3500 Ft</strong> (az ár a postaköltséget és kezelési költséget
          tartalmazza). Csak előre utalással lehet fizetni. Szállítási idő <strong class="text-gray-800">3-4
            munkanap</strong>.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.heart-list-item {
  position: relative;
  padding-left: 30px;
  /* Adjust space for the heart icon */
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.heart-list-item::before {
  content: '❤️';
  position: absolute;
  left: 0;
  top: 0;
  color: red;
  /* Adjust the color of the heart icon as needed */
  font-size: 1rem;
  /* Adjust the size of the heart icon as needed */
}
.max-container {
  max-width: 800px;
  margin: auto;
  text-align: left;
}</style>