<template>
  <section class="bg-white py-8">
    <div class="max-container">
      <div class="container mx-auto">
        <h2 class="text-3xl font-bold text-center text-gray-800 mb-6">Vélemények</h2>
        <div class="space-y-4">
          <div class="border p-4 rounded-lg shadow">
            <p class="text-lg text-gray-600">"Gyönyörű, művészi igényű mesekönyv, imádom a rajzok stílusát, csak ajánlani
              tudom!"</p>
            <p class="text-right text-sm font-semibold mt-2">- Viki, Izland</p>
          </div>
          <div class="border p-4 rounded-lg shadow">
            <p class="text-lg text-gray-600">"Végre egy keménytáblás lapozó ebben a témában, nagyon sokat nézegetjük a
              kisfiammal!"</p>
            <p class="text-right text-sm font-semibold mt-2">- Tünde, Győr</p>
          </div>
          <!-- Additional made-up reviews -->
          <div class="border p-4 rounded-lg shadow">
            <p class="text-lg text-gray-600">"Nekünk nagyon bevált a könyv, felkeltette a lányom érdeklődését a téma
              iránt, sokszor kérte, hogy olvassam el neki."</p>
            <p class="text-right text-sm font-semibold mt-2">- Andrea, Debrecen</p>
          </div>
          <div class="border p-4 rounded-lg shadow">
            <p class="text-lg text-gray-600">"Nagyon szépek és élethűek a rajzok, most ez a legkedvesebb könyve a
              kisfiamnak."</p>
            <p class="text-right text-sm font-semibold mt-2">- Judit, Budapest</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.max-container {
  max-width: 800px;
  margin: auto;
  text-align: left;
}</style>