<template>
  <section class="bg-white py-8 px-4">
    <div class="max-container">
      <div class="container mx-auto">
        <h2 class="text-3xl font-bold text-center text-gray-800 mb-6">A szerzőről</h2>
        <p class="text-lg text-gray-600 leading-relaxed">
          A könyv írója és illusztrátora Hermán Alíz képzőművész, akinek további alkotásai a <a class="underline"
            href="http://www.hermanaliz.hu">www.hermanaliz.hu</a> címen megtekinthetők.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.max-container {
  max-width: 800px;
  margin: auto;
  text-align: left;
}
</style>
