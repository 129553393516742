import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import Aszf from '../views/AszfPage.vue';
import Adatkezelesi from '../views/AdatkezelesiPage.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/aszf', component: Aszf },
  { path: '/adatkezelesi-tajekoztato', component: Adatkezelesi },
  { path: '/submit-order', component: Home }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
