<template>
  <MiscHeaderComponent />
  <div class="max-container">
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold text-center mb-6">Adatkezelési tájékoztató</h1>

      <!-- 1. Üzemeltetői adatok -->
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">1. Az adatkezelési tájékoztató célja</h2>
        <p>Jelen dokumentum célja, hogy Hermán Alíz, mint adatkezelő (a továbbiakban egységesen Adatkezelő) leírja az
          Adatkezelő szervezetében alkalmazott és működő, személyes adatnak minősülő adatokra vonatkozó adatvédelmi
          szabályokat, eljárásokat és védelmi intézkedéseket.</p>
        <p>Az Adatkezelő egyúttal e dokumentumban tájékoztatja ügyfeleit, partnereit, illetőleg minden olyan természetes-
          és
          jogi személyt, akik az Adatkezelővel bármilyen – jogi szempontból értelmezhető – kapcsolatban állnak és a
          személyes kezelése során érintettségük keletkezik, az általa kezelt személyes adatok kezelésének szabályairól,
          az
          alkalmazott védelmi intézkedésekről eljárásokról és az adatkezelés módjáról.</p>
        <p>Az Adatkezelő jelen Adatkezelési tájékoztatóban leírt szabályokat, rendelkezéseket és kötelezettségeket saját
          magára jogilag kötelező érvényűnek tekinti és működése során alkalmazza, valamint kijelenti, hogy jelen
          dokumentumban leírt és alkalmazott adatvédelmi szabályok és eljárások megfelelnek a hatályos adatvédelmi nemzeti
          és Európai Uniós jogszabályoknak.</p>
        <p>Az Adatkezelési tájékoztató mindenkor hatályos verziója a <a
            href="http://bilisztori.hu/adatkezeles">bilisztori.hu/adatkezeles</a> hozzáférhetőségeken érhető el.</p>
      </div>
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">2. Az Adatkezelő adatai</h2>
        <h3 class="text-xl font-bold mb-3">2.1 Az Adatkezelő cégadatai</h3>
        <ul>
          <li>Név: Hermán Alíz</li>
          <li>Székhely: 8808 Nagykanizsa, Diófa utca 2.</li>
          <li>Adószám: 77082112-1-40</li>
          <li>Központi telefonszám: +36 30 977 9394</li>
          <li>Központi e-mail: info@bilisztori.hu</li>
        </ul>

        <h3 class="text-xl font-bold mb-3">2.2 Az Adatkezelő elérhetőségei</h3>
        <p>Email: info@bilisztori.hu</p>
        <p>Telefonszám: +36 30 977 9394</p>
        <h3 class="text-xl font-bold mb-3">2.3 Adatkezelő Adatvédelmi tisztviselője</h3>
        <p>Név: Hermán Alíz</p>
        <p>Telefonszám: +36 30 977 9394</p>
        <p>Email cím: info@bilisztori.hu</p>
      </div>
      <div class="p-4 bg-white shadow-lg rounded-lg my-4 text-left">
        <h2 class="text-xl font-bold mb-2">3 Adatkezelő Adatkezelései és a kezelt személyes adatok</h2>
        <h3 class="text-xl font-bold mb-3">3.1 Online rendeléshez kapcsolódó adatok</h3>
        <ul>
          <li>Név: elengedhetetlen a megrendelés feldolgozásához és számla kiállításához.</li>
          <li>Email cím, elengedhetetlen a megrendelővel való kapcsolattartásra.</li>
          <li>Cím (település, utca, házszám), elengedhetetlen a számla kiállításához.</li>
          <li>Telefonszám: nem kötelező, viszont megkönnyítheti a kapcsolattartást.</li>
        </ul>
        <p>Az adatkezelés jogalapja: az Érintett online alapú hozzájárulása</p>
        <p>Az adatkezelés célja: a pontos árajánlat meghatározása, és a rendelt termék elküldése.</p>
        <p>Az adatszolgáltatás elmaradásának lehetséges következményei: A fent felsorolt adatok hiányossága esetén a
          szolgáltatás nyújtása nem lehetséges.</p>
        <p>Az adatkezelésben kezelt adatok: név, email cím (település, utca, házszám)</p>
        <p>Az adatkezelésben kezelt adatok megőrzési ideje: a legutolsó megrendeléstől számított öt év</p>

        <h3 class="text-xl font-bold mb-3">3.3 Online ügyintézéshez, kapcsolat felvételi űrlapokhoz kapcsolódó adatok</h3>
        <ul>
          <li>Név, elengedhetetlen a kapcsolatfelvételhez.</li>
          <li>Email cím, elengedhetetlen, az Érintett a megadott email címen kap választ az ügyintézéssel kapcsolatban.
          </li>
        </ul>
        <p>Az adatkezelés jogalapja: Az Érintett online alapú hozzájárulása.</p>
        <p>Az adatkezelés célja: Kapcsolattartás az Érintettel.</p>
        <p>Az adatszolgáltatás elmaradásának lehetséges következményei: az online ügyintézés, tájékoztatás nem jöhet
          létre.
        </p>
        <p>Az adatkezelésben kezelt adatok: Név, email cím.</p>
        <p>Az adatkezelésben kezelt adatok megőrzési ideje: a legutolsó kapcsolat felvételtől számított öt év.</p>

        <h3 class="text-xl font-bold mb-3">3.4 Technikai adatok</h3>
        <p>Technikai adatok, azon adatok, amelyek az Adatkezelő rendszereinek működése során többnyire automatikusan
          keletkeznek és kerülnek rögzítésre. Egyes technikai adatokat a rendszer, az Érintett külön nyilatkozata, vagy
          cselekménye nélkül is tárol és bizonyos esetekben automatikusan naplóz. A technikai adatok az Érintett
          személyének
          azonosítására közvetlenül nem alkalmasak, azonban összekapcsolhatók felhasználói adatokkal, így az azonosítás
          elvileg lehetővé válik. Ilyen adatkapcsolatokat az Adatkezelő nem készít, kivéve egyes olyan esetekben, amikor
          erre Adatkezelőt törvény kötelezi. A technikai adatokhoz kizárólag az Adatkezelő és Adatfeldolgozói férhetnek
          hozzá.</p>
        <p>Az adatkezelés jogalapja: a szolgáltatás nyújtásához technikailag feltétlenül szükséges.</p>
        <p>Az adatkezelés célja: a rendelt szolgáltatás elvégzése, termék elkészítése.</p>
        <p>Az adatszolgáltatás elmaradásának lehetséges következményei: szolgáltatás nyújtása, termék elkészítése nem
          lehetséges.</p>
        <p>Az adatkezelésben kezelt adatok: név, email cím, cím.</p>

        <h3 class="text-xl font-bold mb-3">3.6 Böngésző Süti – Cookie</h3>
        <h4 class="text-xl font-bold mb-4">3.6.1 A sütik feladata</h4>
        <p>A HTTP-süti (süti, cookie) kisméretű adatcsomag, amelyet az internetes böngészés során a látogatott weboldalt
          tartalmazó szerver hozza létre a kliens webböngészője segítségével, az első látogatás alkalmával, ha ez a
          böngészőben engedélyezve van. A sütik a felhasználó számítógépén, előre meghatározott, böngésző típusonként
          eltérő
          helyen tárolódnak. A további látogatások során a tárolt sütit a böngésző visszaküldi a webszervernek, különféle,
          a
          kliensről szóló információkkal együtt. A sütik segítségével a szervernek lehetősége van az adott felhasználó
          azonosítására, róla különféle információk gyűjtésére és ezekből elemzések készítésére.</p>
        <ul>
          <li>Információkat gyűjtenek a látogatókról és eszközeikről.</li>
          <li>Megjegyzik a látogatók egyéni beállításait, amelyek felhasználásra kerül(het)nek pl. online tranzakciók
            igénybevételekor, így nem kell újra begépelni őket.</li>
          <li>Megkönnyítik, egyszerűsítik, kényelmesebbé, gördülékenyebbé teszik az adott weboldal használatát.</li>
          <li>Szükségtelenné teszik már megadott adatok újra megadását.</li>
          <li>Általában javítanak a felhasználói élményen.</li>
        </ul>
        <p>A sütik használatával az Adatkezelő adatkezelést végez, amelynek fő céljai:</p>
        <ul>
          <li>Felhasználó azonosítás.</li>
          <li>Az egyes munkamenetek azonosítása.</li>
          <li>Hozzáférésre használt eszközök azonosítása.</li>
          <li>Bizonyos megadott adatok tárolása.</li>
          <li>Nyomon követési és helyinformációk tárolása és továbbítása.</li>
          <li>Analitikai mérésekhez szükséges adatok tárolása és továbbítása.</li>
        </ul>
        <p>Az adatkezelés jogalapja: Az Érintett online hozzájárulása.</p>

        <h4 class="text-xl font-bold mb-4">3.6.2 Munkamenet sütik – session cookie</h4>
        <p>Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen böngészhessék a weboldalt, használhassák
          annak funkcióit, és az ott elérhető szolgáltatásokat. Az ilyen típusú sütik érvényességi ideje a munkamenet
          (böngészés) befejezéséig tart, a böngésző bezárásával a sütik e fajtája automatikusan törlődik a számítógépről,
          illetve a böngészésre használt más eszközről.</p>

        <h4 class="text-xl font-bold mb-4">3.6.3 Harmadik fél által elhelyezett analitikai sütik – analytics cookie</h4>
        <p>Az Adatkezelő weboldalán alkalmazza a Google Analytics mint harmadik fél sütijeit is. A Google Analytics
          statisztikai célú szolgáltatás használatával az Adatkezelő szervere információkat gyűjt azzal kapcsolatban, hogy
          a látogatók hogyan használják a weboldalt. Az adatot a honlap fejlesztésének és a felhasználói élmény
          javításának céljával használja fel. Ezen sütik szintén lejáratukig a látogató számítógépén vagy böngészésre
          használt más eszközén, annak böngészőjében maradnak, illetve amíg a látogató nem törli őket.</p>

        <h4 class="text-xl font-bold mb-4">3.6.4 Facebook pixel (Facebook cookie)</h4>
        <p>A Facebook-képpont olyan kód, amelynek a segítségével a honlapon jelentés készül a konverziókról, célközönségek
          állíthatók össze, és az oldal tulajdonosa részletes elemzési adatokat kap a látogatók honlap használatáról. A
          Facebook pixel segítségével a weboldal látogatóinak személyre szabott ajánlatokat, hirdetéseket jeleníthet meg a
          Facebook felületén. A Facebook adatkezelési szabályzatát itt tanulmányozhatja: <a
            href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p>

        <h4 class="text-xl font-bold mb-4">3.6.4 Sütik letiltásának-, sütikkel kapcsolatos szabályok beállításának lehetősége</h4>
        <p>Az Érintettnek lehetősége van bizonyos típusú sütikre vonatkozó szabályok beállítására, pl. a sütik
          használatának mellőzésére, a sütik letiltására stb., a használt böngésző megfelelő beállításaival. A sütik
          szelektív, vagy általános tiltásának beállítási lehetőségeiről szóló információk az adott böngésző
          „Segítség/Help” menüjében találhatóak meg. Ezek segítségével a sütik:</p>
        <ul>
          <li>Általánosan letiltani.</li>
          <li>A sütik elfogadásának módját beállítani (automatikus elfogadás, egyenként kérdezzen rá, stb.).</li>
          <li>Egyenként letiltani.</li>
          <li>Egyenként, vagy csoportosan törölni.</li>
          <li>Egyéb sütikkel kapcsolatos műveleteket elvégezni.</li>
        </ul>
        <p>Amennyiben Ön nem fogadja el a cookie-k használatát, akkor bizonyos funkciók nem lesznek elérhetőek az Ön
          számára. A cookie-k törléséről bővebb tájékoztatást az alábbi linkeken találhat:</p>
        <ul>
          <li>Internet Explorer: <a
              href="http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11</a>
          </li>
          <li>Firefox: <a
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a>
          </li>
          <li>Mozilla: <a
              href="https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito">https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito</a>
          </li>
          <li>Safari: <a
              href="https://support.apple.com/kb/ph21411?locale=en_US">https://support.apple.com/kb/ph21411?locale=en_US</a>
          </li>
          <li>Chrome: <a
              href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script>
import MiscHeaderComponent from '../components/MiscHeaderComponent.vue';
export default {
  name: 'AdatkezelesiPage',
  components: {
    MiscHeaderComponent,
  }
};
</script>
  
<style scoped>
.max-container {
  max-width: 800px;
  margin: auto;
  text-align: left;
}</style>
