<template>
    <!-- Submission Message -->
    <div v-if="showSubmissionMessage" :class="{
        'bg-green-600': submissionStatus === 'success',
        'bg-yellow-600': submissionStatus === 'warning',
        'bg-red-600': submissionStatus === 'alert'
    }" class="fixed top-0 left-0 right-0 text-white py-2 px-4 text-center z-50">
        {{ submissionMessage }}
    </div>
    <section class="order-form bg-white py-8 px-4" ref="orderForm">
        <div class="container mx-auto">
            <a id="megrendeles"></a>
            <div class="container mx-auto" v-if="!isOrderSubmitted">
                <h2 class="text-3xl font-bold text-center text-gray-800 mb-6">Megrendelőlap</h2>
                <form @submit.prevent="submitOrder" method="post" class="w-full max-w-lg mx-auto">
                    <!-- Full Name Field -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="fullname">Név</label>
                        <input ref="nameInput" v-model="order.name"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="fullname" type="text" placeholder="Teljes név" required>
                    </div>

                    <!-- Email Field -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="email">E-mail cím</label>
                        <input ref="emailInput" v-model="order.email"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email" type="email" placeholder="Email" required>
                    </div>

                    <!-- Phone Number Field -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="phone">Telefonszám</label>
                        <input ref="phoneInput" v-model="order.phone"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="phone" type="tel" placeholder="Telefonszám" required>
                    </div>

                    <!-- Zip Code Field -->
                    <div class="mb-4">
                        <label for="zip" class="block text-gray-700 text-sm font-bold mb-2 text-left">Irányítószám</label>
                        <input ref="zipInput" v-model="order.zip" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" pattern="[0-9]{4}" maxlength="4" title="Négyjegyű irányítószám" placeholder="Irányítószám" required>
                    </div>

                    <!-- City Field -->
                    <div class="mb-4">
                        <label for="city" class="block text-gray-700 text-sm font-bold mb-2 text-left">Város</label>
                        <input ref="cityInput" v-model="order.city" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Város" required>
                    </div>

                    <!-- Address Field -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="address">Szállítási cím</label>
                        <input ref="addressInput" v-model="order.address"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="address" type="text" placeholder="Cím" required>
                    </div>

                    <!-- Quantity Selector and Price Display -->
                    <div class="mt-4 flex items-center justify-center">
                        <button @click="decreaseQuantity" type="button" class="bg-gray-300 text-gray-700 px-3 py-1 rounded-l-md">-</button>
                        <input type="text" v-model="order.quantity" class="w-12 text-center border-t border-b" readonly>
                        <button @click="increaseQuantity" type="button" class="bg-gray-300 text-gray-700 px-3 py-1 rounded-r-md">+</button>
                        <span class="ml-4 text-lg font-semibold">Ára: {{ computedPrice }} Ft</span>
                    </div>

                    <!-- Submit Button -->
                    <div class="flex items-center justify-center mt-6">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
                            Megrendelés
                        </button>
                    </div>
                </form>
            </div>
            <div class="container mx-auto text-center" v-else>
                <!-- Thank you message -->
                <h2 class="text-3xl font-bold mb-4">Köszönjük a megrendelését!</h2>
                <p>Az Ön megrendelése sikeresen rögzítésre került. Hamarosan küldünk egy megerősítő emailt.</p>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    mounted() {
        this.$refs.nameInput.removeAttribute('required');
        this.$refs.emailInput.removeAttribute('required');
        this.$refs.phoneInput.removeAttribute('required');
        this.$refs.zipInput.removeAttribute('required');
        this.$refs.cityInput.removeAttribute('required');
        this.$refs.addressInput.removeAttribute('required');
    },
    data() {
        return {
            price: 3500,
            order: {
                quantity: 1,
                name: '',
                email: '',
                phone: '',
                city: '',
                zip: '',
                address: ''
            },
            submissionMessage: '',
            submissionStatus: '', // 'success', 'warning', or 'alert'
            showSubmissionMessage: false,
            isOrderSubmitted: false,
        };
    },
    computed: {
        computedPrice() {
            return this.order.quantity * this.price;
        }
    },
    methods: {
        async submitOrder() {
            this.submissionMessage = '';
            // Validate form fields
            if (!this.order.name.trim()) {
                this.submissionMessage = 'Kérjük, adja meg a teljes nevét!';
                this.$refs.nameInput.focus(); // Focus on the email input
            }
            else if (!this.order.email.trim()) {
                this.submissionMessage = 'Kérjük, adja meg az e-mail címét!';
                this.$refs.emailInput.focus(); // Focus on the email input
            }
            else if (!this.order.phone.trim()) {
                this.submissionMessage = 'Kérjük, adja meg a telefonszámát!';
                this.$refs.phoneInput.focus(); // Focus on the email input
            }
            else if (!this.order.zip) {
                this.submissionMessage = 'Kérjük, adja meg az irányítószámát!';
                this.$refs.zipInput.focus(); // Focus on the email input
            }
            else if (!this.order.city.trim()) {
                this.submissionMessage = 'Kérjük, adja meg a települést!';
                this.$refs.cityInput.focus(); // Focus on the email input
            }
            else if (!this.order.address.trim()) {
                this.submissionMessage = 'Kérjük, adja meg a címét!';
                this.$refs.addressInput.focus(); // Focus on the email input
            }

            if (!this.order.name.trim() || !this.order.email.trim() || !this.order.phone || !this.order.address.trim() || !this.order.city.trim() || !this.order.zip) {
                this.submissionStatus = 'warning';
                this.showSubmissionMessage = true;
                setTimeout(() => {
                    this.showSubmissionMessage = false;
                }, 5000);
                return;
            }

            try {
                const response = await fetch('/submit-order', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.order)
                });

                const responseData = await response.json();

                if (!response.ok) {
                    if (responseData.message) {
                        this.submissionMessage = responseData.message;
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } else {
                    if (responseData.message) {
                        this.submissionStatus = 'warning';
                        this.submissionMessage = responseData.message;
                    }
                    // this.submissionMessage = `Köszönjük a megrendelést! Rendelési azonosító: ${responseData.order_id}`;
                }

                // Success handling
                this.showSubmissionMessage = true;

                if (responseData.success) {
                    this.isOrderSubmitted = true;
                    this.submissionStatus = 'success';
                } else {
                    this.submissionStatus = 'alert';
                }
                
                setTimeout(() => {
                    this.showSubmissionMessage = false;
                }, 5000);
            } catch (error) {
                console.error('Error submitting order:', error);
                this.submissionStatus = 'alert';
                this.showSubmissionMessage = true;
                this.submissionMessage = `Hiba a megrendelés elküldésekor: ${error.message}`;
                setTimeout(() => {
                    this.showSubmissionMessage = false;
                }, 5000);
            }
        },
        scrollToOrderForm() {
            const orderFormElement = this.$refs.orderForm.$el; // Access the DOM element
            orderFormElement.scrollIntoView({ behavior: 'smooth' });
        },
        increaseQuantity() {
            this.order.quantity++;
        },
        decreaseQuantity() {
            if (this.order.quantity > 1) {
                this.order.quantity--;
            }
        }
    }

};
</script>
  
<style>
form {
    margin-bottom: 40px
}
</style>
