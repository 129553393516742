<template>
  <div :style="backgroundImageStyle" class="masthead-container bg-cover bg-center">
    <div class="masthead text-white py-20">
      <div class="container mx-auto">
        <div class="text-center">
          <h1 class="text-4xl mb-3 font-bold"><router-link to="/" class="underline" title="Kezdőlap">Bilisztori</router-link></h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiscHeaderComponent',
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${require('@/assets/sample.jpg')})`
      };
    }
  }
};
</script>

<style scoped>
.masthead-container {
  /* Adjust the height, background size, or other properties as needed */
  background-size: cover;
  background-position: center;
}

.masthead {
  /* Style for the inner content of the masthead */
  padding: 5rem 1rem; /* Example padding, adjust as needed */
  text-align: center;
}

.btn-primary {
  background-color: #007bff; /* Example primary color, adjust as needed */
  color: white;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;

  /* Hover state */
  &:hover {
    background-color: #0056b3;
  }
}
</style>
