<template>
  <div class="gallery flex justify-center items-center my-8">
    <button @click="showPrevious" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-l">
      &lt;
    </button>
    <img :src="currentImage" alt="Book Image" class="w-1/2 h-auto shadow-lg mx-4" />
    <button @click="showNext" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r">
      &gt;
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Use require to resolve the path to your images
      images: [
        require('@/assets/cover.jpg'), // Assuming the image is in src/assets
//        require('@/assets/sample.jpg'), // Replace with the path to another image
        require('@/assets/opened.jpg'), // Replace with the path to another image
        require('@/assets/sample2.jpg'), // Replace with the path to another image
        require('@/assets/sample3.jpg'), // Replace with the path to another image
      ],
      currentIndex: 0
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    }
  },
  methods: {
    showNext() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    showPrevious() {
      this.currentIndex = (this.currentIndex + this.images.length - 1) % this.images.length;
    }
  }
};
</script>
  