<template>
  <div :style="backgroundImageStyle" class="masthead-container bg-cover bg-center">
    <div class="masthead text-white py-20">
      <div class="container mx-auto">
        <div class="text-center">
          <h1 class="text-6xl mb-3 font-bold">Bilisztori</h1>
          <h2 class="text-xl mb-5">Egy kedves történet egy testvérpárról, ami a szobatisztaság útján segíti elindulni a legkisebbeket.</h2>
          <p>
            <a href="#megrendeles" @click="scrollToOrderForm" class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-6 py-3 rounded text-lg">
              Rendeld meg most!
              <i class="fa fa-shopping-cart ml-2" aria-hidden="true"></i>
            </a>
          </p>
          <p class="mt-4">
            <a href="#reszletek" class="text-lg underline">
              <i class="fa fa-arrow-circle-down mr-2" aria-hidden="true"></i>
              Részletek
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="arrow-container">
      <i class="fa fa-arrow-down arrow-icon" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${require('@/assets/sample.jpg')})`
      };
    }
  }
};
</script>

<style scoped>
.masthead-container {
  /* Updated styles */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
}

.arrow-container {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
}

.arrow-icon {
  font-size: 2rem; /* Adjust size as needed */
}

/* Keyframes for the bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>
