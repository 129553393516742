<template>
  <div>
    <HeaderComponent />
    <BookOverviewComponent />
    <AuthorInfoComponent />
    <GalleryComponent />
    <CustomerReviewsComponent />
    <OrderFormComponent />
  </div>
</template>
  
<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import BookOverviewComponent from '../components/BookOverviewComponent.vue';
import AuthorInfoComponent from '../components/AuthorInfoComponent.vue';
import GalleryComponent from '../components/GalleryComponent.vue';
import CustomerReviewsComponent from '../components/CustomerReviewsComponent.vue';
import OrderFormComponent from '../components/OrderFormComponent.vue';

export default {
  components: {
    HeaderComponent,
    BookOverviewComponent,
    AuthorInfoComponent,
    GalleryComponent,
    CustomerReviewsComponent,
    OrderFormComponent
  }
};
</script>
  