<template>
  <footer class="site-footer bg-slate-800 text-white py-6">
    <div class="container mx-auto py-4 px-5">
      <p class="text-gray-500 text-sm text-center sm:text-left">
        Lépjen kapcsolatba velünk az <a href="mailto:info@bilisztori.hu"
          class="text-blue-500 underline">info@bilisztori.hu</a> címen
      </p>
      
      <p class="text-gray-500 text-sm text-center sm:text-left">
        <router-link to="/aszf" class="text-blue-500 underline">Általános Szerződési Feltételek (ÁSZF)</router-link> | 
        <router-link to="/adatkezelesi-tajekoztato" class="text-blue-500 underline">Adatkezelési tájékoztató</router-link>
      </p>
      <p class="text-gray-500 text-sm text-center sm:text-left">
        Bilisztori © 2024. Minden jog fenntartva. | Készítette: Csiszár István
      </p>
    </div>
  </footer>
</template>


<style>
/* Additional custom styles if needed */
</style>
